<template>
  <v-app>
    <v-layout justify-center align-center class="bg-white">
      <v-layout class="box-noauth bg-main" />
      <v-layout class="w-main h-wrap-auth pos-rel py-8">
        <h2 class="logo pos-abs" />
        <v-layout justify-center align-center>
          <v-layout column class="form login-frm box-shadow px-13 py-16">
            <label
              class="text-uppercase font-weight-medium text-size-title mt-8 text-center"
            >
              {{ $t('update_password') }}
            </label>
            <p class="mt-2 mb-10 text-body-2 text-center">
              {{ $t('please_enter_new_password') }}
            </p>
            <validation-observer ref="observer" v-slot="{ invalid }">
              <v-form @submit.prevent="submit">
                <v-layout column>
                  <label class="text-body-2">
                    {{ $t('new_password') }}
                    <span class="red--text">{{ $t('required') }}</span>
                  </label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="password"
                    :rules="passwordRules"
                  >
                    <v-text-field
                      v-model="password"
                      outlined
                      dense
                      class="rounded-lg"
                      :append-icon="isPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="isPassword ? 'password' : 'text'"
                      :maxlength="maximumPassword"
                      :error-messages="errors"
                      :placeholder="$t('place_holders.password')"
                      @click:append="isPassword = !isPassword"
                    />
                  </validation-provider>
                </v-layout>
                <v-layout column>
                  <label class="text-body-2">
                    {{ $t('confirm_password') }}
                    <span class="red--text">{{ $t('required') }}</span>
                  </label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="confirmPassword"
                    :rules="confirmPasswordRules"
                  >
                    <v-text-field
                      v-model="confirmPassword"
                      outlined
                      dense
                      class="rounded-lg"
                      :append-icon="
                        isPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      :type="isPasswordConfirm ? 'password' : 'text'"
                      :maxlength="maximumPassword"
                      :error-messages="errors"
                      :placeholder="$t('place_holders.confirn_password')"
                      @click:append="isPasswordConfirm = !isPasswordConfirm"
                    />
                  </validation-provider>
                </v-layout>
                <div>
                  <v-btn
                    block
                    large
                    color="primary"
                    type="submit"
                    class="rounded-lg mt-10 mb-8"
                    :disabled="invalid"
                  >
                    <span class="font-weight-medium white--text">
                      {{ $t('continue') }}
                    </span>
                  </v-btn>
                </div>
              </v-form>
            </validation-observer>
          </v-layout>
        </v-layout>
      </v-layout>
    </v-layout>

    <!-- Dialog loading -->
    <dialog-loading v-model="showLoading" :message="$t('processing')" />

    <!-- notifications -->
    <dialog-notification
      v-model="showNoti"
      :persistent="persistent"
      :icon-src="getIcon"
      :message="message"
      :is-show-brand="isShowBrand"
    />
  </v-app>
</template>

<script>
import DialogLoading from '@/components/dialog/DialogLoading.vue'
import DialogNotification from '@/components/dialog/DialogNotification.vue'
import constants from '@/constants'
import routePaths from '@/router/routePaths'
import { FormUtils } from '@/helpers/formUtils'
import { StringUtils } from '@/helpers/stringUtils'
import { StorageService } from '@/services/storageService'
import { mapActions } from 'vuex'

export default {
  components: { DialogLoading, DialogNotification },
  data: () => ({
    password: '',
    isPassword: true,
    maximumPassword: constants.maximumPassword,
    passwordRules: {
      required: true,
      min: constants.minimumPassword,
      max: constants.maximumPassword,
      regex: constants.regExp.password
    },
    confirmPassword: '',
    isPasswordConfirm: true,
    confirmPasswordRules: {
      required: true,
      confirmed: 'password'
    },

    // loading
    showLoading: false,

    // notifications
    showNoti: false,
    persistent: false,
    typeNoti: constants.typeAlert.warning,
    message: '',
    isShowBrand: false
  }),
  computed: {
    getIcon() {
      return StringUtils.getIconSrc(this.typeNoti)
    }
  },
  created() {
    const valid = this.$store.state.user.checkCurrentPassword
    if (!valid) {
      this.gotoLogin()
    }
  },
  methods: {
    ...mapActions('user', ['changePasswordUpdate']),
    async submit() {
      var valid = this.$refs.observer.validate()
      if (valid) {
        this.showLoading = true
        const params = {
          password: this.password.trim(),
          confirmPassword: this.confirmPassword.trim()
        }
        const { status, data } = await this.changePasswordUpdate(params)
        this.showLoading = false
        if (status === constants.statusCode.ok) {
          this.toggleDialogNoti({
            state: true,
            persistent: true,
            typeAlert: constants.typeAlert.success,
            msg: this.$t('update_password_success')
          })
          setTimeout(() => {
            this.gotoLogin()
          }, constants.timeOut)
        } else {
          this.toggleDialogNoti({ state: true, msg: data.message })
          setTimeout(() => {
            this.toggleDialogNoti()
          }, constants.timeOut)
        }
      }
    },
    gotoLogin() {
      StorageService.clearLocalStorage()
      this.$router.push(routePaths.LOGIN)
    },
    isNumber(evt) {
      return FormUtils.isNumber(evt)
    },
    toggleDialogNoti(
      pars = {
        state: false,
        persistent: false,
        type: constants.typeAlert.warning,
        msg: ''
      }
    ) {
      this.showNoti = pars.state
      this.persistent = pars.persistent
      this.typeNoti = pars.type
      this.message = pars.msg
    }
  }
}
</script>
<style src="@/styles/form.scss" lang="scss" />
